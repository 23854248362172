<template>
  <!-- 角色管理 -->
  <div>
    <div class="form">
      <h4>角色管理</h4>
      <div class="borce">
        <el-button size="medium" type="success" @click="newpags">新增</el-button>
      </div>
    </div>
    <!-- 表单区域 -->
    <div style="margin-top: 30px">
      <el-table :data="data" border style="width: 100%">
        <el-table-column prop="roleName" label="角色名称" width="260"> </el-table-column>
        <el-table-column prop="roleComments" label="备注" width="260"> </el-table-column>
        <el-table-column prop="Sphone" label="角色是否可用" width="260">
          <template slot-scope="scope">
            <!-- {{ !scope.row.del ? "可用" : "不可用" }} -->
            <span :class="!scope.row.del ? 'keyong' : 'bukeyong'">{{ !scope.row.del ? '可用' : '不可用' }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="xiugai(scope.row.id, scope.$index)">修改</el-button>
            <el-button size="mini" type="danger" @click="open(scope.row.id, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页选项 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: null,
      currentPage: 1,
      pageNumber: 10,
      data: null
    }
  },
  created() {
    // 获取角色列表
    this.goRoleList()
  },
  methods: {
    // 获取角色列表
    goRoleList() {
      this.axios
        .get('/admin/role/selelctRoleList', {})
        .then(res => {
          console.log(res)
          this.data = res.data.data.records
          this.data = this.data.filter(item => item.del !== 1)
          this.total = res.data.data.total
          this.currentPage = res.data.data.current
          this.pageNumber = res.data.data.size
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取失败!'
          })
        })
    },
    open(id, index) {
      let userID = JSON.parse(sessionStorage.getItem('admin')).adminId
      this.$confirm('确定删除此角色吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.axios.delete(`/admin/role/delRole?id=${id}&userId=${userID}`, {}).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              // 改变状态值元素
              // this.data.splice(index, 1);
              this.data[index].del = 1
              this.total--
              this.goRoleList()
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            } else {
              this.$message({
                type: 'error',
                message: res.data.mesg
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: '',
            message: '取消删除'
          })
        })
    },
    on() {
      console.log(1211113)
    },
    // 新增添加角色页面
    newpags() {
      // alert("新增管理员页面");
      this.$router.push('addsystemRole')
    },
    // 修改角色
    xiugai(id, idnex) {
      // alert("新增管理员页面");
      this.$router.push({ path: 'addsystemRoleaaa', query: { id } })
    },

    // 分页
    handleSizeChange(val) {
      this.pageNumber = val
      this.axios
        .get('/admin/role/selelctRoleList', {
          pageNumber: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          console.log(res)
          this.data = res.data.data.records
        })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.axios
        .get('/admin/role/selelctRoleList', {
          pageNumber: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          console.log(res)
          this.data = res.data.data.records
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  justify-content: space-between;
  h4 {
    color: #409eff;
  }
  .borce {
    margin-top: 10px;
    margin-right: 17px;
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
.cell {
  color: #606266;
}
.keyong {
  color: green;
}
.bukeyong {
  color: red;
}
</style>
